<template>
  <div>
    <!-- /.modal -->

    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark capitalize">Refund Request</h1>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>

    <data-tables-master-data
      :config="config"
      class="container-fluid"
      id="sampleTable"
    >
      <template v-slot:item.path="{ item }">
        <v-select v-model="cSel" :items="item.path"> </v-select>
      </template>
    </data-tables-master-data>
  </div>
</template>

<script>
import DataTablesMasterData from "@components/DataTablesMasterData.vue";

export default {
  components: {
    DataTablesMasterData,
  },

  data() {
    return {
      active: false,
      errorDialog: null,
      errorText: "",
      uploadFieldName: "file",
      error: undefined,
      loading: false,
      text_validation: null,
      maxSize: 1024,
      form: {
        status: "",
      },
      disabled: false,
      cSel: "A",
      res: [
        {
          path: ["A", "B", "C", "D"],
        },
      ],
      config: {
        columns: [
          {
            title: "id",
            data: "id",
            visible: false,
          },
          {
            title: "Issue Date",
            data: "created",
            render: (val) => {
                return this.formatDate(val)
            },
          },
          {
            title: "Process Date",
            data: "prosess_datetime",
            render: (val) => {
                return this.formatDate(val)
            },
          },
          {
            title: "Transaction ID",
            data: "transaction.transaction_code",
          },
          {
            title: "Machine ID",
            data: "vending.vending_id",
          },
          {
            title: "Nominal Refund",
            data: "amount_refund",
            render: (val) => {
              return `Rp. ${this.formatPrice(val)}`;
            },
          },
          {
            title: "Price Refund",
            data: "price_refund",
            render: (val) => {
              return `Rp. ${this.formatPrice(val === undefined ? 0 : val)}`;
            },
          },
          {
            title: "Status Refund",
            data: "status",
            render: (val, i, row) => {
              return `
                        <div> 
                            <button type="button" class="btn dropdown-toggle refund-request-select" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                ${val}
                            </button>
                            <div class="dropdown-menu">
                                <ul>
                                    <li class="red-color"><a class="dropdown-item red-color refund-action" href="#" data-id="${row.id}" data-status="Rejected">Rejected</a></li>
                                    <li class="green-color"><a class="dropdown-item green-color refund-action" href="#" data-id="${row.id}" data-status="Refund Parsial">Refund Parsial</a></li>
                                    <li class="blue-color"><a class="dropdown-item text-primary refund-action" href="#" data-id="${row.id}" data-status="Refund Void">Refund Void</a></li>
                                <ul>
                            </div>
                        </div>`;
            },
          },
        ],
        drawCallback: (settings) => {
          $(".refund-action").on("click", (e) => {
            this.putDataRefund(
              $(e.target).closest("a").data("id"),
              $(e.target).closest("a").data("status")
            );
          });
        },
        url: this.Api.base + "/refundRequest",
        order: [[0, "desc"]],
      },
    };
  },
  mounted() {},
  props: {
    value: Object,
  },
  methods: {
    formatDate(val){
        return moment(val).format("DD-MMM-YYYY HH:mm");
    },
    add() {},
    putDataRefund(id, status) {
      this.form.status = status;
      this.Api.put(`/refundRequest/${id}`, this.form)
        .then((res) => {
          this.loading = false;
          this.refresh();
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.response.data.message;
          this.text_validation = "Submit Failed";
        });
    },
    refresh() {
      this.config = {
        url: this.Api.base + "/refundRequest",
      };
    },
    formatPrice(value) {
      return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
